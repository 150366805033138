import { post, _axios } from "../util/http"
/**
 * 登录
 * @param  params 
 */
export const LoginRequest = params => post(`/login/login`, params)

/**
 * 登出
 * @param  params 
 */
export const LogoutRequest = params => post(`/user/logout`, params)



/**
 * 上传附件
 *
 * @param {Object}
 * @return Result
 */
export const UploadFile = (params, callback) => _axios.post('/file/upload', params, {
  headers: { 'Content-Type': 'multipart/form-data' },
  onUploadProgress (progressEvent) {
    if(progressEvent.lengthComputable){
      //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
      //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
      callback(progressEvent);
    }
  }
})