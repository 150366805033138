<template>
  <el-container class="main">
    <!--头部-->
    <el-header class="header">
      <!--LOGO-->
      <div class="logo">
        <!-- <img class="logo-icon" src="../../../assets/img/logo.png" /> -->
        <!-- <GPT class="iconlogo"></GPT> -->
        <div class="sys-name" @click="$router.push('/admin')">
          <div class="sys-title">{{ $store.getters.appName }}</div>
          <div class="sys-title-zy">{{ $store.getters.appNameZw }}</div>
        </div>
      </div>
      <!--头部多功能区域-->
      <div class="about">
        <el-dropdown>
          <div class="el-dropdown-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="svg-icon"
              viewBox="0 0 24 24"
              width="34"
              height="34"
              style="fill: rgb(253, 250, 250);"
            >
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 0 1-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 0 1-6 3.22z"
              />
            </svg>
            <span>{{$store.getters.username}}</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item @click.native="shouUpPWD">修改密码</el-dropdown-item> -->
            <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <!--主体-->
    <div class="mainBody">
      <div class="body">
        <!--左侧菜单-->
        <div
          class="left-body"
          :class="{'left-menu-collapse': isCollapse, 'isMobile':  isCollapse && isMobile}"
        >
          <el-scrollbar class="left-menu">
            <nja-menu :menus="menuTree" :default-active="defaultActive" :collapse.sync="isCollapse"></nja-menu>
          </el-scrollbar>
          <div
            class="left-menu-footer"
            :class="{'isMobileMenu': isMobile}"
            @click="isCollapse = !isCollapse"
          >
            <i :class="['icon', !isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold']"></i>
          </div>
        </div>
        <el-scrollbar class="body-main" v-show="!isMobile || (isCollapse && isMobile)">
          <div class="breadcrumb" v-if="currentBreadcrumb">
            <span>当前位置：</span>
            <el-breadcrumb separator="/">
              <el-breadcrumb-item
                v-for="(menuName, i) in currentBreadcrumb"
                :key="`breadcurm${i}`"
              >{{menuName}}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <!--右侧主体-->
          <router-view />
        </el-scrollbar>
      </div>
    </div>
    <UpPwdDialog ref="pwdDialogRef"></UpPwdDialog>
  </el-container>
</template>
<script>
import NjaMenu from '@/components/Menu'
import UpPwdDialog from './upPwdDialog'
export default {
  components: {
    NjaMenu,
    // GPT,
    UpPwdDialog
  },
  data() {
    return {
      isCollapse: false,
      isMobile: false,
      showRightLayout: false,
      search: '',
      showLogin: false,
      loginForm: {
        username: '',
        password: ''
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  watch: {},
  computed: {
    menuTree() {
      console.log('this.$store.getters.menuTree', this.$store.getters.menuTree)
      // let menu = this.$store.getters.menuTree.filter(
      //   (d) => d.menuUri === this.rootPage.path
      // )
      // if (menu && menu.length > 0) {
      //   return menu[0].children
      // }
      return this.$store.getters.menuTree
    },
    rootPage() {
      let m = this.$route.matched
      if (m && m.length > 0) {
        return m[0]
      }
      return ''
    },
    defaultActive() {
      return this.$route.path
    },
    currentBreadcrumb() {
      let current = this.$store.getters.menus.find(
        (d) => d.menuUri === this.$route.path
      )
      console.log('======', current)
      if (current && current.showCrumb) {
        if (this._.isEmpty(current.parents)) {
          return [this.$route.meta.title]
        }
        return [...current.parents, this.$route.meta.title]
      }
      return false
    }
  },
  methods: {
    shouUpPWD() {
      this.$refs.pwdDialogRef.show()
    },
    menuClick(menu) {
      // console.log(menu)
      if (menu.menuUri) {
        this.$router.push(menu.menuUri)
      } else {
        this.$message({
          message: '未开发。。',
          type: 'warning'
        })
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(() => {
          done()
        })
        .catch(() => {})
    },
    logout() {
      this.$confirm('确定退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.commit('setMenus', [])
        sessionStorage.removeItem('token')
        localStorage.removeItem('config-user')
        location.reload()
      })
    },
    getIsMobile() {
      this.isMobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      if (this.isMobile) {
        this.isCollapse = true
      }
    }
  },
  created() {},
  mounted() {
    this.getIsMobile()
    this.$event.$on('click-menu', () => {
      if (this.isMobile) {
        this.isCollapse = true
      }
    })
    window.addEventListener('resize', (e) => {
      console.log('resize', e)
      this.getIsMobile()
      if (window.innerWidth < 640) {
        this.isMobile = true
        this.isCollapse = true
      } else {
        this.isMobile = false
        this.isCollapse = false
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.main {
  background-color: #f5f7f9;
}
.header {
  height: 50px !important;
  line-height: 50px !important;
  // border-bottom: 1px solid #e1e2e3;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 3px 0 #eae9e9;
  margin-bottom: 10px;
  color: #fff;
  background-color: $primary-color;
  .logo {
    display: flex;
    flex-direction: row;
    color: #fff;
    font-weight: 800;
    // letter-spacing: 8px;
    align-items: center;
    cursor: pointer;
    max-width: 80%;
    overflow: hidden;
    overflow-x: auto;
    .logo-icon {
      height: 50px;
      background-color: rgba($color: #18a058, $alpha: 0.9);
      border: 1px solid #fff;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .sys-name {
      margin-left: 15px;
      line-height: 1;
      text-align: left;
      .sys-title {
        word-break: keep-all; /* 不换行 */
        white-space: nowrap; /* 不换行 */
        font-weight: 900;
        font-size: 24px;
        letter-spacing: 0.2em;
        font-weight: 700;
        text-shadow: 1px 2px 1px rgba(100, 100, 100, 0.3);
      }
      .sys-title-zy {
        word-break: keep-all; /* 不换行 */
        white-space: nowrap; /* 不换行 */
        font-weight: 600;
        font-size: 20px;
        margin-top: 2px;
      }
    }
    .gotoIndex {
      &:hover {
        color: yellow;
      }
    }
  }

  .el-dropdown-link {
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .about {
    display: flex;
    flex-direction: row;
    color: $primary-color;
    padding-right: 20px;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    .input {
      width: 200px;
    }
    .svg-icon {
      margin-right: 10px;
    }
    .icon {
      color: #666;
      margin: 10px;
      cursor: pointer;
    }
  }
}
.mainBody {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  .rightLayout {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 300px;
    border-left: 1px solid #eee;
    height: calc(100vh - 50px);
    background-color: #fafafa;
    z-index: 999;
    padding: 10px;
    .rightHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      i {
        font-size: 30px;
        color: #999;
        cursor: pointer;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
  .body {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 14px;
    .left-body {
      width: 200px;
      transition: all 0.3s ease-in-out;
      height: calc(100vh - 60px);
      background-color: #fff;
      position: relative;
      .left-menu {
        height: calc(100% - 50px);
        overflow-y: auto;
        overflow-x: hidden;
      }
      .left-menu-footer {
        position: absolute;
        z-index: 20;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 45px;
        line-height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #f1f2f3;
        cursor: pointer;
        background-color: #fff;
        &:hover {
          background-color: #f1f1f1;
          .icon {
            color: #999;
          }
        }
        .icon {
          font-size: 24px;
          color: #ccc;
        }
      }
      .isMobileMenu {
        width: 80px !important;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .el-menu {
      text-align: left;
      border: 0px;
    }
    .left-menu-collapse {
      width: 64px !important;
    }
    .isMobile {
      width: 0px !important;
    }
    .body-main {
      width: 100%;
      // padding: 10px;
      margin-left: 15px;
      overflow: hidden;
      // height: 100vh;
      box-sizing: border-box;
      height: calc(100vh - 60px);
      //   background-color: red;
      .breadcrumb {
        display: flex;
        height: 20px;
        line-height: 20px;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}
.loginForm {
  padding: 20px;
}
@media only screen and (max-width: 640px) {
  .sys-name {
    // visibility: hidden;
    // display: none;
    .sys-title {
      font-size: 14px;
      letter-spacing: 0.5em;
    }
    .sys-title-zy {
      font-size: 10px;
      margin-top: 2px;
    }
  }
  .mainBody .body .body-main {
    padding: 5px;
  }
}
.iconlogo {
  width: 30px;
  height: 30px;
  color: rgba($color: #fff, $alpha: 0.9);
}
</style>
