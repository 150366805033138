export default [
  {
    "id": "1",
    "menuUri": "/admin/stock",
    "menuName": "个股查询",
    "parentId": null,
    "menuIcon": "el-icon-house",
    "showCrumb": true
  },
  {
    "id": "2",
    "menuUri": "/admin/selection",
    "menuName": "个股初选",
    "parentId": null,
    "menuIcon": "el-icon-guide",
    "showCrumb": true
  }, {
    "id": "3",
    "menuUri": "/admin/label",
    "menuName": "分类标签",
    "parentId": null,
    "menuIcon": "el-icon-collection-tag",
    "showCrumb": true
  }
]