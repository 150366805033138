<!-- 修改密码 -->
<template>
  <el-dialog
    class="pwd-dialog"
    title="修改密码"
    align="center"
    :close-on-click-modal="false"
    :visible.sync="visible"
    v-dialogDrag
    width="400px"
  >
   <div class="msg">
      <i class="el-icon-warning"></i> 新密码需要八位字符
    </div>
    <el-form :model="form" ref="handleForm" label-position="top" label-width="100px" @submit.native.prevent>
      <el-form-item
        label=""
        prop="oldpwd"
        required
        :rules="[ { required: true, message: '必填' }]"
      >
        <el-input v-model="form.oldpwd" type="password" placeholder="请输入原密码" clearable>
            <template slot="prepend">原密码</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label=""
        prop="password"
        required
        :rules="[ { required: true, message: '必填' }]"
      >
        <el-input v-model="form.password" type="password" placeholder="请输入新密码" clearable>
           <template slot="prepend">新密码</template>
        </el-input>
      </el-form-item>
    </el-form>
    
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" native-type="submit" style="width: 120px;" @click="submit">保 存</el-button>
      <el-button @click="visible = false" style="width: 120px;">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { UpdatePwd } from '@/api'
import { md5 } from '@/util/crypto'
export default {
  data() {
    return {
      visible: false,
      form: {
        password: '',
        oldpwd: ''
      }
    }
  },
  methods: {
    show() {
      this.visible = true
      this.$nextTick(() => {
        this.$refs['handleForm'].clearValidate()
      })
    },
    submit() {
      this.$refs['handleForm'].validate((valid) => {
        if (valid) {
          let uppass = {
            username: this.$store.getters.username,
            oldpwd: md5(this.form.oldpwd),
            password: md5(this.form.password)
          }
          UpdatePwd(uppass).then((res) => {
            console.log('updatePwd', res)
            if (res.code === 20000) {
              this.visible = false
              this.$confirm(`修改密码成功，请重新登录?`, '提示', {
                type: 'warning'
              }).then(() => {
                this.$store.commit('setMenus', [])
                sessionStorage.removeItem('token')
                localStorage.removeItem('config-user')
                location.reload()
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pwd-dialog {
  .msg {
    // text-align: center;
    margin-bottom: 10px;
  }
  ::v-deep {
    .el-dialog__body {
      padding: 10px 20px;
    }
  }
  .dialog-footer {
    text-align: center;
  }
}
</style>