<template>
  <component :is="(menu.children && menu.children.length > 0) ? 'el-submenu' : 'el-menu-item'" :index="menu.menuUri">
    <template v-if="(menu.children && menu.children.length > 0)">
       <template slot="title">
        <i :class="menu.menuIcon" v-if="menu.menuIcon"></i>
        <span slot="title">{{menu.menuName}}</span>
      </template>
      <nja-menu-item v-for="(m, i) in menu.children" :key="i" :menu="m"></nja-menu-item>
    </template>
     <template v-else>
        <i :class="menu.menuIcon" v-if="menu.menuIcon"></i>
        <span slot="title">{{menu.menuName}}</span>
      </template>
  </component>
</template>

<script>
export default {
  name: 'nja-menu-item',
  props: {
    menu: {
      type: Object,
      default () {
        return {}
      }
    },
    index: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
</style>
