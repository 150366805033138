/**
 * 本地缓存Cache
 * 1 全局可用，在page,comp,js等等中均可使用
 * 2 绑定到VUE原型上，页面刷新同样可用
 * 3 尽量避免缓存大量数据（超过1MB的数据）
 *
 * 使用方法：
 * 1 添加缓存
 *   调用this.$setCache(key, value)方法全局缓存数据value，key{String}为自定义键值
 *   例如
 *   const value = { a: 'abc', b: 123, c: [1, 2, 3] }
 *   this.$setCache('cacheKey001', value)
 * 2 获取缓存
 *   调用方法：this.$getCacheObject(key)，获取指定key的缓存数据
 *   this.$getCacheObject('cacheKey001') => { a: 'abc', b: 123, c: [1, 2, 3] }
 *   调用方法：this.$getCache(key, subKey)，获取指定key的缓存数据中的subKey对应的值
 *   this.$getCache('cacheKey001', 'a') => 'abc'
 *   this.$getCache('cacheKey001', 'b') => 123
 *   this.$getCache('cacheKey001', 'c') => [1, 2, 3]
 *
 * @author zhangbowen, dangminjing, luwei
 * @version 1.0.0, 2019-04-24
 */
import {decryptByAES, encryptByAES} from './crypto'
class Cache {
  constructor() {
    this.data = {}
  }
  setCache(key, value) {
    if (typeof value === 'string') {
      localStorage.setItem(key, encryptByAES(value))
    } else {
      localStorage.setItem(key, encryptByAES(JSON.stringify(value)))
    }
  }
  getCacheObject(key) {
    let all = decryptByAES(localStorage.getItem(key))
    if (all) {
      this.data = JSON.parse(all)
    }
    return this.data
  }
  getCache(key, subKey) {
    let all = decryptByAES(localStorage.getItem(key))
    if (all) {
      this.data = JSON.parse(all)
      return this.data[subKey]
    }
    return null
  }
}

let getLocalCache = new Cache()

export default {
  // eslint-disable-next-line no-unused-vars
  install: function(Vue, option) {
    /**
     * 添加全局缓存
     * @param {String} key 缓存键值
     * @param {Object} value 缓存值对象
     */
    Vue.prototype.$setCache = function(key, value) {
      getLocalCache.setCache(key, value)
    }
    /**
     * 获取全局缓存中指定的子键的值
     * @param {String} key 缓存键值
     * @param {Object} subKey 缓存对象的子键值
     * @returns {Object} value.subKey
     */
    Vue.prototype.$getCache = function(key, subKey) {
      return getLocalCache.getCache(key, subKey)
    }
    /**
     * 获取全局缓存值对象
     * @param {String} key 缓存键值
     * @returns {Object} value
     */
    Vue.prototype.$getCacheObject = function(key) {
      return getLocalCache.getCacheObject(key)
    }
  }
}
