import Layout from '@/views/admin/common/layout'
/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    redirect: '/admin'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: { title: '登录', keepAlive: false, index: 0, anonymous: true }
  }
]

/**
 * 自定义路由
 * @type { *[] }
 */
export const customRouterMap = [
  {
    path: '/admin', // 后台
    name: 'admin',
    component: Layout,
    // redirect: () => '/admin/index',
    redirect: '/admin/stock',
    children: [
      {
        path: '/admin/stock',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/biz/stock'),
        meta: { title: '个股查询', keepAlive: false}
      },
      {
        path: '/admin/selection',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/biz/selection'),
        meta: { title: '个股初选', keepAlive: false}
      },
      {
        path: '/admin/label',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/biz/label'),
        meta: { title: '分类标签', keepAlive: false}
      },
     ]
  },
  {
    path: '*',
    component: () => import('@/views/admin/common/404'),
    meta: { title: '找不到页面', keepAlive: false, anonymous: true}
  }
]

